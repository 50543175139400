<template>
<div class="descrption dirr">
    <p class="dirr tl d-none d-sm-flex">{{ $t("holy_shrine_desc") }}</p>
    <div class="descCon">
        <div class="nav d-none d-sm-flex">
            <v-btn-toggle v-model="toggle_desc">
                <v-btn outlined class="src" :value="'desc'+(i+1)" v-for="(x, i) in descres" :key="x" @click="showDesc(x.id)">
                    <div :style="'background-image:url(https://alkafeel.net/description2/images/upload/resized2/'+x.pic+');'" class="img"></div>
                    <p class="tl">{{x.title}}</p>
                </v-btn>
            </v-btn-toggle>
        </div>
        <div class="slider navbar d-flex d-sm-none" :class="{ 'hidden-navbar': !showNavbar }" >
            <p class="dirr tl">{{ $t("holy_shrine_desc") }}</p>
            <v-btn-toggle v-model="toggle_desc">
            <swiper class="swiper dirr" ref="mySwiper3" :options="swiperOptions3">
                <swiper-slide v-for="(x, i) in descres" :key="x">
                    <v-btn outlined class="src" :value="'desc'+(i+1)" @click="showDesc(x.id)">
                        <div :style="'background-image:url(https://alkafeel.net/description2/images/upload/resized2/'+x.pic+');'" class="img"></div>
                        <p class="tl">{{x.title}}</p>
                    </v-btn>
                </swiper-slide>
            </swiper>
            </v-btn-toggle>
        </div>
        <div class="content" ref="content">
            <div class="contentCon" >
                <div class="item" v-for="i in showres" :key="i">
                    <div class="title">
                        <span></span>
                        <p class="tl">{{i.title}}</p>
                    </div>
                    <img :src="'https://alkafeel.net/description2/images/upload/resized2/'+i.pic" alt="">
                    <p class="tx" v-html="i.text"></p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script>
// import Axios from "axios";
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
const OFFSET = 200;
export default {
   metaInfo() {
    return {
     
    };
  },
    data() {
    return {
        descres : [],
        showres: [],
        toggle_desc : 'desc1',
        swiperOptions3: {
            slidesPerView: 'auto',
            // spaceBetween: 3,
            freeMode: true,
            keyboard: true,
        },
        showNavbar: true,
        lastScrollPosition: 0,
        scrollValue: 0
    }
    },
    components: {
        // Swiper,
        // SwiperSlide
    },
    directives: {
        // swiper: directive
    },
    computed: {
        // swiper() {return this.$refs.mySwiper3.$swiper}
    },
    mounted() {  
        document.title =this.$t("holy_shrine_desc");  
        this.lastScrollPosition = window.pageYOffset
        window.addEventListener('scroll', this.onScroll)
        const viewportMeta = document.createElement('meta')
        viewportMeta.name = 'viewport'
        viewportMeta.content = 'width=device-width, initial-scale=1'
        document.head.appendChild(viewportMeta);
    }, 
    created () {
        this.getDescs ();
    },
    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods:{
        getDescs () {
            this.$axios.get("alkafeel_description/cats/", {
            })
            .then(res => {
                this.descres = res.data;
                this.showDesc(res.data[0].id);
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        showDesc (x) {
            this.$axios.get("alkafeel_description/bycatId/"+x, {
            })
            .then(res => {
                this.showres = res.data;
                window.scrollTo(0,0);
                this.$refs.content.scrollTop = 0;
                //console.log(res.data);
            })
            .catch(() => {
            });
        },
        onScroll () {
            if (window.pageYOffset < 0) {return}
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {return}
            this.showNavbar = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },
    },
}
</script>